import {Link, useLocation} from "react-router-dom";
import {routePath} from "../../../constants/routes";
import logo from '../../../assets/images/crypto_hub_logo.png'
import {Dropdown} from "../../../components";
import {useBasePath} from "../../../hooks/useBasePath";
import {NetworkCurrencyEnum, NetworkType} from "../../../pages/ConsolidationTool/types";
import {objectKeys} from "../../../helpers";

const activeClass = 'text-cyan-400'

export const Header = () => {
  const currentPath = useBasePath()
  const {pathname} = useLocation();

  function isActive(path: string) {
    if (currentPath === path) {
      return activeClass
    }

    return ''
  }

  const consolidationMenu = objectKeys(NetworkCurrencyEnum).reduce<{
    key: NetworkType
    label: React.ReactNode
  }[]>((acc, currency) => {
    if (NetworkCurrencyEnum[currency]) {
      acc.push({
        key: currency,
        label: (
          <Link to={routePath.consolidation_tool + '/' + currency}>
            <img src={require(`../../../assets/images/${currency}.svg`)}
                 className={`min-w-[108px] w-[108px] h-6 object-contain object-left ${pathname === (routePath.consolidation_tool + '/' + currency) ? 'drop-shadow-black' : ''}`}
                 width={108} height={24} alt={currency}/>
          </Link>
        )
      })
    }

    return acc
  }, [])

  const disperseMenu = objectKeys(NetworkCurrencyEnum).reduce<{
    key: NetworkType
    label: React.ReactNode
  }[]>((acc, currency) => {
    if (
      NetworkCurrencyEnum[currency] === NetworkCurrencyEnum.bsc ||
      NetworkCurrencyEnum[currency] === NetworkCurrencyEnum.base ||
      NetworkCurrencyEnum[currency] === NetworkCurrencyEnum.eth ||
      NetworkCurrencyEnum[currency] === NetworkCurrencyEnum.pol ||
      NetworkCurrencyEnum[currency] === NetworkCurrencyEnum.avax ||
      NetworkCurrencyEnum[currency] === NetworkCurrencyEnum.sol
    ) {
      acc.push({
        key: currency,
        label: (
          <Link to={routePath.disperse_tool + '/' + currency}>
            <img src={require(`../../../assets/images/${currency}.svg`)}
                 className={`min-w-[108px] w-[108px] h-6 object-contain object-left ${pathname === (routePath.disperse_tool + '/' + currency) ? 'drop-shadow-black' : ''}`}
                 width={108} height={24} alt={currency}/>
          </Link>
        ),
      })
    }

    return acc
  }, [])

  return (
    <nav className="grid gap-10 grid-cols-3 items-center h-[50px] px-5 shadow-md bg-[#182828]/70 text-white">
      <div className="flex items-center gap-2.5">
        <img src={logo} width={40} height={40} alt="crypto_hub_logo"/>
        <div className="text-lg font-bold">
          CryptoHub
        </div>
      </div>


      <div className="flex gap-10 items-center justify-center">
        <Link to={routePath.gas_tracker}>
          <span
            className={`drop-shadow-[2px_4px_6px_black] hover:opacity-70 transition-all text-lg font-medium ${isActive(routePath.gas_tracker)}`}>Gas tracker</span>
        </Link>

        <Dropdown menu={consolidationMenu}>
           <span
             className={`drop-shadow-[2px_4px_6px_black] hover:opacity-70 transition-all text-lg font-medium ${isActive(routePath.consolidation_tool)}`}>
          Consolidation tool
           </span>
        </Dropdown>


        <Dropdown menu={disperseMenu}>
          <span
            className={`drop-shadow-[2px_4px_6px_black] hover:opacity-70 transition-all text-lg font-medium ${isActive(routePath.disperse_tool)}`}>
          Disperse tool
           </span>
        </Dropdown>
      </div>
    </nav>
  )
}