import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import polygonLogo from '../../assets/images/pol-icon.svg';

const POL_DEFAULT_IMG = polygonLogo as string

const POLTokens: ITokenDict = {
  [NetworkCurrencyEnum.pol]: {
    "title": "Polygon",
    "img": "https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg",
    "rate": "$0.45",
    "address": undefined,
    "link": "https://polygonscan.com/",
    "group": `Base Currency (${NetworkCurrencyEnum.pol})`,
    "website": "https://polygon.technology/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.pol
  },
  POL: {
    "title": "Polygon token",
    "img": polygonLogo,
    "rate": "$0.44",
    "address": "0x0000000000000000000000000000000000001010",
    "link": "https://polygonscan.com/token/0x0000000000000000000000000000000000001010",
    "group": `Tokens (BEP 20)`,
    "website": "https://www.optimism.io/",
    "desc": "",
    "decimal": 18,
    "symbol": "POL"
  },
  USDT: {
    "title": "Token USDT",
    "img": "https://arbiscan.io/token/images/tether_32.png",
    "rate": "$1.00",
    "address": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "link": "https://polygonscan.com/token/0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    "group": `Tokens (BEP 20)`,
    "website": "https://tether.to/",
    "desc": "",
    "decimal": 6,
    "symbol": "USDT"
  },
}

export {POLTokens, POL_DEFAULT_IMG}