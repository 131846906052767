import {IContractAbiFragment} from "../../models/chainScan.models";

/**
 * more info by next links
 * https://polygonscan.com/address/0xD152f549545093347A162Dce210e7293f1452150#code
 * https://disperse.app/
 * https://disperse.app/disperse.pdf
 */
const POLDisperseContractAddress = "0xD152f549545093347A162Dce210e7293f1452150" as const

const POLTestnetDisperseContractAddress = "0x6311D01452aEa251a95717DCf38C6C21Cb5B6DAa" as const
const MAX_TX_IN_ETH = 800
const MAX_TX_IN_TOKEN = 180
const POLDisperseABI: IContractAbiFragment[] = [{
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseTokenSimple",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseToken",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "recipients", "type": "address[]"}, {"name": "values", "type": "uint256[]"}],
  "name": "disperseEther",
  "outputs": [],
  "payable": true,
  "stateMutability": "payable",
  "type": "function"
}]

export {
  POLDisperseABI,
  POLDisperseContractAddress,
  POLTestnetDisperseContractAddress,
  MAX_TX_IN_ETH,
  MAX_TX_IN_TOKEN
}