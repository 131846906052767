import {SOLFacade} from "./SOLFacade";
import {
  AddressType, BalanceDataByAddress,
  EstimateResultType,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenInfo,
  ITransactionPriorityEnum,
  NetworkCurrencyEnum, PrivateKeyType
} from "../../types";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import {JsonRpcResponseWithResult} from "web3";
import {GetTokenAccountsByOwnerParsedResponse} from "../../../../store/web3/web3Sol";
import {PublicKey} from "@solana/web3.js";
import {
  Account,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddressSync,
  getMultipleAccounts,
  getOrCreateAssociatedTokenAccount
} from "@solana/spl-token";
import {IRpcResponse} from "../../../../models/chainScan.models";
import {HexStr} from "../../../../store/web3/web3";
import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import * as Sentry from "@sentry/react";
import {partitionArrayIntoChunks} from "../../../../helpers";


interface IDataForGenerateSPLTransactions extends IDataForGenerateTransactions {
  privateKeyByAddress: IMapValueByAddress<PrivateKeyType>,
  baseCurrencyBalanceData: BalanceDataByAddress,
  tokenBalanceData: BalanceDataByAddress,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType
}

export interface ITxSplData extends IGeneralTxData {
  source: PublicKey,
  destination: PublicKey,
  owner: PublicKey,
  amount: number | bigint,
}

interface IDataForSendSPLTransactions extends IDataForSendTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  tokenBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<PrivateKeyType>,
  transactionDataByAddress: IMapValueByAddress<ITxSplData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType
}

class SPLFacade extends SOLFacade implements IWeb3TokenFacade {
  protected readonly limitPrivateKeys;
  protected readonly addressesChunkSize;

  constructor() {
    super();

    this.limitPrivateKeys = 990
    this.addressesChunkSize = 990
  }

  getLimitPrivateKeys(): number {
    return this.limitPrivateKeys
  }

  getAddressesChunkSize(): number {
    return this.addressesChunkSize
  }

  async fetchBaseCurrencyBalanceDataByAddress(addressList: Set<AddressType>): Promise<BalanceDataByAddress> {
    return await super.fetchBaseCurrencyBalanceDataByAddress(addressList)
  }

  async fetchTokenBalanceDataByAddress(addressList: Set<AddressType>, tokenAddress: string): Promise<BalanceDataByAddress> {

    const balanceTokenByAddress: BalanceDataByAddress = new Map();
    if (tokenAddress) {
      this._web3ProviderLimit = 1000
      const {BatchRequest, getParsedTokenAccountsByOwner} = this._web3Provider

      /**
       * Method getParsedTokenAccountsByOwner doesn't work with more than 210 addresses in one query
       * For preventing errors make chunks with 100 addresses
       */
      for (const addressesChunk of partitionArrayIntoChunks(Array.from(addressList), 100)) {
        const batchInfo = new BatchRequest();
        for (const address of addressesChunk) {
          batchInfo.add(getParsedTokenAccountsByOwner.request(new PublicKey(address), new PublicKey(tokenAddress)));
        }

        const dataInfo = await batchInfo.execute({timeout: 30000})
        for (let item of dataInfo) {
          if (item.error) {
            const errorData = item.error as JsonRpcError
            throw Error(errorData.message + `[${errorData.code}]`)
          }
          let itemSuccess = item as JsonRpcResponseWithResult<GetTokenAccountsByOwnerParsedResponse>

          for (const balanceInfo of itemSuccess.result.value) {
            if (balanceInfo.account.data.parsed.info.mint === tokenAddress) {
              let balanceToken = balanceInfo.account.data.parsed.info.tokenAmount.amount

              if (getParsedTokenAccountsByOwner.outputFormatter(balanceToken) > 0) {
                balanceTokenByAddress.set(itemSuccess.id!.toString(), getParsedTokenAccountsByOwner.outputFormatter(balanceToken || 0))
              }
              break;
            }
          }
        }
      }
    }

    return balanceTokenByAddress
  }

  async generateTransactions(data: IDataForGenerateSPLTransactions, tokenAddress?: AddressType): Promise<EstimateResultType> {
    if (!tokenAddress) return super.generateTransactions(data)

    const {baseCurrencyBalanceData, tokenBalanceData, privateKeyByAddress, receiverAddress, transactionPriority} = data
    const {getKeyPair, PublicKey} = this._web3Provider

    const txDataByAddress: IMapValueByAddress<ITxSplData> = new Map()
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()

    const toWallet = new PublicKey(receiverAddress);
    const tokenWallet = new PublicKey(tokenAddress!)

    if (tokenBalanceData.size === 0) {
      return {txDataByAddress, feeDataByAddress}
    }

    const {
      senderAtaByAddress,
      receiverAta
    } = await this.__getOrCreateAssociatedTokenAccount(
      baseCurrencyBalanceData,
      tokenBalanceData,
      privateKeyByAddress,
      tokenWallet,
      toWallet
    )

    if (!senderAtaByAddress.size) {
      return {txDataByAddress, feeDataByAddress}
    }

    let _payerAddress: AddressType = '';
    baseCurrencyBalanceData.forEach((_balance, _address) => {
      if (_balance > this._feeDefaultInLamports && senderAtaByAddress.has(_address)) {
        _payerAddress = _address
        return
      }
    });

    if (!_payerAddress) {
      const {value} = senderAtaByAddress.keys().next()
      _payerAddress = value
    }

    const signer = getKeyPair(privateKeyByAddress.get(_payerAddress)!)
    const {
      totalFee,
    } = await this._getPriorityFeeEstimate(
      createTransferInstruction(
        senderAtaByAddress.get(_payerAddress)!.address,
        receiverAta.address,
        senderAtaByAddress.get(_payerAddress)!.owner,
        0
      ),
      signer,
      transactionPriority
    )


    for (const address of tokenBalanceData.keys()) {
      const balanceToken = tokenBalanceData.get(address)
      if (!senderAtaByAddress.has(address) || !tokenBalanceData.has(address)) {
        continue
      }
      if (address.toLowerCase() === receiverAddress?.toLowerCase()) {
        continue
      }
      if (balanceToken > 0 && baseCurrencyBalanceData.get(address)! > 0) {
        const senderAta = senderAtaByAddress.get(address)!

        /**
         * Create token transfer instructions for transaction
         * @see createTransferInstruction
         */
        const txData = {
          source: senderAta.address,
          destination: receiverAta.address,
          owner: senderAta.owner,
          amount: balanceToken,
        }

        txDataByAddress.set(address, txData)
      }

      feeDataByAddress.set(address, totalFee)
    }

    return {txDataByAddress, feeDataByAddress}
  }

  async sendTransactions(data: IDataForSendTransactions, tokenAddress?: AddressType): Promise<IMapValueByAddress> {
    if (!tokenAddress) return super.sendTransactions(data)


    const {
      privateKeyByAddress, baseCurrencyBalanceData, transactionPriority, transactionDataByAddress
    } = data as IDataForSendSPLTransactions
    const resultTxReceipt: IMapValueByAddress<string> = new Map()
    if (transactionDataByAddress.size === 0) {
      return resultTxReceipt
    }

    const {
      getLatestBlockhash, BatchTransaction, BatchRequest,
      sendRawTransaction, getKeyPair
    } = this._web3Provider


    const signedTxByAddress = new BatchRequest()

    //getPriorityFee by first tx
    const [_addressSender, _txParams]: [AddressType, ITxSplData] = transactionDataByAddress.entries().next().value;
    const signer = getKeyPair(privateKeyByAddress.get(_addressSender)!)
    const {
      totalFee,
      computeLimitIx,
      computePriceIx
    } = await this._getPriorityFeeEstimate(
      createTransferInstruction(_txParams.source, _txParams.destination, _txParams.owner, _txParams.amount),
      signer,
      transactionPriority
    )

    for (const address of transactionDataByAddress.keys()) {
      const txParams = transactionDataByAddress.get(address)

      const mainBalance = (baseCurrencyBalanceData.get(address) || BigInt(0)) - totalFee
      if (mainBalance <= 0) {
        continue
      }

      const signer = getKeyPair(privateKeyByAddress.get(address)!)

      const {blockhash} = await getLatestBlockhash("finalized")
      let transaction = new BatchTransaction({
        recentBlockhash: blockhash,
      });

      if (computePriceIx) {
        transaction.add(
          computePriceIx,
        )
      }
      transaction.add(
        computeLimitIx,
        createTransferInstruction(txParams.source, txParams.destination, txParams.owner, txParams.amount)
      )
      transaction.sign(signer)

      signedTxByAddress.add(sendRawTransaction.request(transaction.serialize(), address, {
        skipPreflight: true,
        maxRetries: 150
      }));
    }

    const dataBatchTx = await signedTxByAddress.execute({timeout: 30000})
    for (let txResult of dataBatchTx) {
      if (txResult.error) {
        const errorData = txResult.error as JsonRpcError
        throw new Error(`${errorData.message} [${errorData.code}]`)
      }
      let itemSuccess = txResult as IRpcResponse
      if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
        itemSuccess.result += '?cluster=devnet'
      }
      resultTxReceipt.set(itemSuccess.id, itemSuccess.result as HexStr)
    }

    return resultTxReceipt
  }


  async fetchTokenInfo(tokenAddress: string): Promise<ITokenInfo> {
    const {fetchTokenInfoDev, fetchTokenInfoMain} = this._web3Provider

    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      return fetchTokenInfoDev(tokenAddress)
    }

    return fetchTokenInfoMain(tokenAddress)
  }


  private async __getOrCreateAssociatedTokenAccount(baseCurrencyBalanceData: BalanceDataByAddress,
                                                    tokenBalanceData: BalanceDataByAddress,
                                                    privateKeyByAddress: IMapValueByAddress, tokenWallet: PublicKey,
                                                    toWallet: PublicKey) {
    const {connection, getKeyPair} = this._web3Provider
    const senderAtaByAddress: IMapValueByAddress<Account> = new Map()
    /**
     * Get associated token accounts (ATA) for token senders
     * if transaction did to the sender address than we can expect to get ATA in other case will be return null
     */


    const sourceAtaArr = []
    let _errorData;
    for (const address of tokenBalanceData.keys()) {
      if (tokenBalanceData.get(address)) {
        const fromKeyPair = getKeyPair(privateKeyByAddress.get(address)!);

        sourceAtaArr.push(getAssociatedTokenAddressSync(
          tokenWallet,
          fromKeyPair.publicKey
        ));
      }
    }
    const reponseAccount = await getMultipleAccounts(connection, sourceAtaArr)
    reponseAccount.forEach(account => senderAtaByAddress.set(account.owner.toBase58(), account))

    // Create associated token accounts (ATA) for receiver of token if they don't exist yet
    let destinationAccount: Account;
    try {
      const associatedToken = getAssociatedTokenAddressSync(
        tokenWallet,
        toWallet
      );
      destinationAccount = await getAccount(connection, associatedToken)
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          section: "IWeb3Facade",
          facade: "SPLFacade",
          method: "getAssociatedTokenAddressSync"
        },
        contexts: {
          "__getOrCreateAssociatedTokenAccount": {
            network: this.network,
            currency: NetworkCurrencyEnum[this.network],
            count_token_keys: tokenBalanceData.size,
          }
        }
      });
      _errorData = e //possible error "TokenAccountNotFoundError" => ATA does not exist
    }

    //If ATA does not exist
    if (_errorData) {
      let maxAmountBalance: [AddressType, bigint] | undefined = undefined;
      baseCurrencyBalanceData.forEach((balance, address, map) => {
        const current: [AddressType, bigint] = [address, balance]
        if (!maxAmountBalance) {
          maxAmountBalance = current
        } else {
          maxAmountBalance = (maxAmountBalance[1] < current[1]) ? current : maxAmountBalance
        }
      })
      /**
       * If ATA does not exist, and we don`t find (maxAmountBalance) who can pay for create ATA in SOLANA chain will to throw exception
       *
       * 0.000005 sol | 5000 Lamport --- it`s default Fee
       */
      if (!maxAmountBalance || maxAmountBalance[1] < BigInt(5000)) {
        console.error('__getOrCreateAssociatedTokenAccount', _errorData, _errorData?.message)
        throw _errorData
      }
      const feePayer = getKeyPair(privateKeyByAddress.get(maxAmountBalance[0])!)

      try {
        destinationAccount = await getOrCreateAssociatedTokenAccount(
          connection,
          feePayer,
          tokenWallet,
          toWallet,
          false,
          "confirmed",// connection must be also "confirmed, possible error "TokenAccountNotFoundError" https://stackoverflow.com/questions/76445810/tokenaccountnotfounderror-encountered-while-trying-to-create-token-account
          {
            commitment: "confirmed",
          }
        );
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            section: "IWeb3Facade",
            facade: "SPLFacade",
            method: "getOrCreateAssociatedTokenAccount"
          },
          contexts: {
            "__getOrCreateAssociatedTokenAccount": {
              network: this.network,
              currency: NetworkCurrencyEnum[this.network],
              count_token_keys: tokenBalanceData.size,
            }
          }
        });
        throw Error(`${e}, try again, probably network overload and timeout by waiting response`)
      }
    }

    return {senderAtaByAddress, receiverAta: destinationAccount}
  }
}

export {SPLFacade};