import {ITransactionPriorityEnum} from "../../ConsolidationTool/types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";
import {GasHelper} from "../../../helpers";
import {AddressHexStr, IContractAbiFragment} from "../../../models/chainScan.models";
import {
  IDataEstimateDisperseTransactions,
  IEstimateFeeForDisperseResultType
} from "../types";
import {
  AVAXDisperseABI,
  AVAXDisperseContractAddress,
  AVAXTestnetDisperseContractAddress
} from "../../../store/avaxscan/AVAXDisperseABI";

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IAVAXGasPrice {
  slow: bigint,
  average: bigint,
  fast: bigint
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IAVAXGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class AVAX_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected _disperseContractABI: IContractAbiFragment[]
  protected _disperseContractAddress: AddressHexStr

  protected static _feeL1ForSingleTransaction: bigint = BigInt(0)
  protected static _feeL1ForMultipleTransaction: bigint = BigInt(0)

  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_AVAX_WEB3_HTTP_PROVIDER,
      network: "avax",
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_AVAX_SCAN,
      defaultTransactionPriority: "fast",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_LINK_FOR_AVAX_GAS_PRICE_API_KEY,
        url: process.env.REACT_APP_LINK_FOR_AVAX_GAS_PRICE_API,
        devUrl: process.env.REACT_APP_LINK_FOR_AVAX_SCAN_API
      },
      addressesChunkSize: 100
    })

    this._disperseContractAddress = process.env.REACT_APP_ENVIRONMENT === 'dev' ?
      AVAXTestnetDisperseContractAddress : AVAXDisperseContractAddress
    this._disperseContractABI = AVAXDisperseABI
  }

  async estimateTransactions(data: IDataEstimateDisperseTransactions): Promise<IEstimateFeeForDisperseResultType> {
    const estimatedFee = await super.estimateTransactions(data)

    estimatedFee.notOptimizedFeeInUnit += GasHelper.gasPricePlusPercent(estimatedFee.notOptimizedFeeInUnit, 10)
    estimatedFee.optimizedFeeInUnit += GasHelper.gasPricePlusPercent(estimatedFee.optimizedFeeInUnit, 10)

    return estimatedFee
  }

  resetInfoForSendTransaction() {
    super.resetInfoForSendTransaction();
    AVAX_DisperseFacade._feeL1ForSingleTransaction = BigInt(0)
    AVAX_DisperseFacade._feeL1ForMultipleTransaction = BigInt(0)
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IAVAXGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const {getGasPriceInWei} = this._web3Provider
      const slowInWei: bigint = await getGasPriceInWei()
      switch (transactionPriority) {
        case 'slow':
          return GasHelper.gasPricePlusPercent(slowInWei, 10)
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
      }
    }

    return gasPriceResult[transactionPriority as keyof IAVAXGasPrice] || BigInt(0)
  }
}

export {AVAX_DisperseFacade}