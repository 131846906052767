import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";
import {
  BscDisperseABI,
  BscDisperseContractAddress,
  BscTestnetDisperseContractAddress
} from "../../../store/bscscan/BEP20DisperseABI";
import {AddressHexStr, IContractAbiFragment} from "../../../models/chainScan.models";
import {ITransactionPriorityEnum} from "../../ConsolidationTool/types";
import {GasHelper} from "../../../helpers";

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IBinanceGasPrice {
  low: bigint;
  medium: bigint,
  high: bigint
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IBinanceGasPrice {
  return {
    low: BigInt(rawResult[1].gasPrice),
    medium: BigInt(rawResult[2].gasPrice),
    high: BigInt(rawResult[3].gasPrice)
  }
}

class BSC_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected _disperseContractABI: IContractAbiFragment[]
  protected _disperseContractAddress: AddressHexStr

  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_BSC_WEB3_HTTP_PROVIDER,
      network: 'bsc',
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BSC_SCAN,
      defaultTransactionPriority: "high",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_LINK_FOR_BSC_GAS_PRICE_API_KEY,
        url: process.env.REACT_APP_LINK_FOR_BSC_GAS_PRICE_API
      },
      //Temporary
      addressesChunkSize: 380 //Because sometimes transactions in batch transaction lost
    })

    this._disperseContractAddress = process.env.REACT_APP_ENVIRONMENT === 'dev' ?
      BscTestnetDisperseContractAddress : BscDisperseContractAddress
    this._disperseContractABI = BscDisperseABI
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IBinanceGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const {getGasPriceInWei} = this._web3Provider
      const slowInWei: bigint = await getGasPriceInWei()
      switch (transactionPriority) {
        case 'low':
          return GasHelper.gasPricePlusPercent(slowInWei, 10)
        case 'medium':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'high':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
      }
    }

    return gasPriceResult[transactionPriority as keyof IBinanceGasPrice] || BigInt(0)
  }
}

export {BSC_DisperseFacade}