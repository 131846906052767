import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import optimismLogo from '../../assets/images/optimism-icon.svg';

const OPT_DEFAULT_IMG = optimismLogo as string

const OPTTokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://basescan.org/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://www.base.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  OP: {
    "title": "Token Optimism",
    "img": optimismLogo,
    "rate": "$2.07",
    "address": "0x4200000000000000000000000000000000000042",
    "link": "https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000042",
    "group": `Tokens (BEP 20)`,
    "website": "https://www.optimism.io/",
    "desc": "",
    "decimal": 18,
    "symbol": "OP"
  },
  USDT: {
    "title": "Token USDT",
    "img": "https://arbiscan.io/token/images/tether_32.png",
    "rate": "$1.00",
    "address": "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    "link": "https://optimistic.etherscan.io/token/0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    "group": `Tokens (BEP 20)`,
    "website": "https://tether.to/",
    "desc": "",
    "decimal": 6,
    "symbol": "USDT"
  },
}

export {OPTTokens, OPT_DEFAULT_IMG}