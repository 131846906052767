import {AddressType, ITransactionPriorityEnum} from "../../ConsolidationTool/types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";
import {GasHelper} from "../../../helpers";
import {AddressHexStr, IContractAbiFragment, ITxDataSimple} from "../../../models/chainScan.models";
import {
  IDataEstimateDisperseTransactions, IDataEstimateUnitsForSingleTransaction,
  IEstimateFeeForDisperseResultType
} from "../types";
import {
  ARBDisperseABI,
  ARBDisperseContractAddress,
  ARBTestnetDisperseContractAddress
} from "../../../store/arbscan/ARBDisperseABI";
import {HexStr, toHex} from "../../../store/web3/web3";
import {ethers} from "ethers";
import {ArbGasInfo__factory} from "@arbitrum/sdk/dist/lib/abi/factories/ArbGasInfo__factory";

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IArbGasPrice {
  slow: bigint,
  average: bigint,
  fast: bigint
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IArbGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class ARB_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected _disperseContractABI: IContractAbiFragment[]
  protected _disperseContractAddress: AddressHexStr

  protected static _feeL1ForSingleTransaction: bigint = BigInt(0)
  protected static _feeL1ForMultipleTransaction: bigint = BigInt(0)

  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_ARB_WEB3_HTTP_PROVIDER,
      network: "arb",
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_ARB_SCAN,
      defaultTransactionPriority: "fast",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_LINK_FOR_ARB_GAS_PRICE_API_KEY,
        url: process.env.REACT_APP_LINK_FOR_ARB_GAS_PRICE_API,
        devUrl: process.env.REACT_APP_LINK_FOR_ARB_SCAN_API
      },
      addressesChunkSize: 50
    })

    this._disperseContractAddress = process.env.REACT_APP_ENVIRONMENT === 'dev' ?
      ARBTestnetDisperseContractAddress : ARBDisperseContractAddress
    this._disperseContractABI = ARBDisperseABI
  }

  async __getGasLimit(sender: AddressType, receiver: AddressType): Promise<bigint> {
    return await this._web3Provider.estimateGas({
      from: sender,
      to: receiver,
    })
  }

  async _estimateUnitsForSingleTransactions(data: IDataEstimateUnitsForSingleTransaction): Promise<bigint> {
    const {senderAccount, amountInUnitByReceiver} = data
    const {receiver} = amountInUnitByReceiver.keys().next().value

    const gasLimit = await this._web3Provider.estimateGas({
      from: senderAccount.address,
      to: receiver,
    })
    return Promise.resolve<bigint>(gasLimit * BigInt(data.amountInUnitByReceiver.size));
  }

  async estimateTransactions(data: IDataEstimateDisperseTransactions): Promise<IEstimateFeeForDisperseResultType> {
    const estimatedFee = await super.estimateTransactions(data)

    const {amountInUnitByReceiver, senderAccount} = data
    const {value: toAddress} = amountInUnitByReceiver.keys().next()

    if (ARB_DisperseFacade._feeL1ForSingleTransaction === BigInt(0)) {
      const estimateFeeL1ForSingleTransactionData: ITxDataSimple = {
        value: toHex(amountInUnitByReceiver.get(toAddress)!),
        from: senderAccount.address,
        to: toAddress
      }
      ARB_DisperseFacade._feeL1ForSingleTransaction = await this._fetchFeeForL1(estimateFeeL1ForSingleTransactionData)
    }
    estimatedFee.notOptimizedFeeInUnit += ARB_DisperseFacade._feeL1ForSingleTransaction * BigInt(amountInUnitByReceiver.size)

    const {getDisperseContract} = this._web3Provider
    const {disperseEther} = getDisperseContract(this._disperseContractABI, this._disperseContractAddress)

    let totalSendInUnit: bigint = BigInt(0)
    amountInUnitByReceiver.forEach(amount => totalSendInUnit += amount)

    if (ARB_DisperseFacade._feeL1ForMultipleTransaction === BigInt(0)) {
      const estimateFeeL1ForMultiTransactionData: ITxDataSimple = {
        value: toHex(totalSendInUnit),
        from: senderAccount.address,
        to: this._disperseContractAddress,
        data: disperseEther(
          [...amountInUnitByReceiver.keys()] as AddressHexStr[],
          [...amountInUnitByReceiver.values()] as bigint[]
        ).encodeABI()
      }
      ARB_DisperseFacade._feeL1ForMultipleTransaction = await this._fetchFeeForL1(estimateFeeL1ForMultiTransactionData)
    }
    estimatedFee.optimizedFeeInUnit += ARB_DisperseFacade._feeL1ForMultipleTransaction

    return estimatedFee
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IArbGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const response = await fetch(`${this._fetchGasPriceConf.devUrl}/eth-rpc`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "id": 0,
          "method": "eth_gasPrice",
          "jsonrpc": "2.0",
          "params": []
        })
      })
      const result = await response.json() as {
        "jsonrpc": string,
        "result": HexStr,
        "id": number
      }
      const slowInWei: bigint = BigInt(result.result)
      switch (transactionPriority) {
        case 'slow':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 200)
      }
    }

    return gasPriceResult[transactionPriority as keyof IArbGasPrice] || BigInt(0)
  }

  async _fetchFeeForL1(transactionData: ITxDataSimple) {
    const arbProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ARB_WEB3_HTTP_PROVIDER);

    try {
      // Step 1: Construct the transaction object
      const transaction = {
        to: transactionData.to,
        value: transactionData.value,
      };

      // Step 2: Estimate calldata size
      const txData = ethers.utils.serializeTransaction(transaction);
      const calldataSize = ethers.utils.hexDataLength(txData);

      // Step 3: Connect to ArbGasInfo contract
      const arbGasInfoAddress = "0x000000000000000000000000000000000000006C"; // ArbGasInfo address
      const arbGasInfo = ArbGasInfo__factory.connect(arbGasInfoAddress, arbProvider);

      // Step 4: Get L1 gas and calldata prices
      const gasPrices = await arbGasInfo.getPricesInWei();
      const l1CalldataPricePerByte = gasPrices[1]; // Price per byte of calldata

      // Step 5: Calculate L1 fee
      const l1Fee = l1CalldataPricePerByte.mul(calldataSize);
      return BigInt(l1Fee.toString())
    } catch (error) {
      return BigInt(0)
    }
  }
}

export {ARB_DisperseFacade}