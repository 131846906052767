import React from "react";
import {ConsolidationToolView} from "./Views/ConsolidationToolView";
import {withFacade} from "./hoc/withFacade";
import {IWeb3Facade} from "./facades/IWeb3Facade";
import {ERC20Facade} from "./facades/ETH_Network/ERC20Facade";
import {BEP20Facade} from "./facades/BSC_Network/BEP20Facade";
import {useParams} from "react-router-dom";
import {NetworkType} from "./types";
import {IWeb3TokenFacade} from "./facades/IWeb3TokenFacade";
import {XRPFacade} from "./facades/XRP_Network/XRPFacade";
import {BASE_ERC20Facade} from "./facades/BASE_Network/BASE_ERC20Facade";
import {SPLFacade} from "./facades/SOL_Network/SPLFacade";
import {DOGEFacade} from "./facades/DOGE_Network/DOGEFacade";
import {ARB_ERC20Facade} from "./facades/ARB_Network/ARB_ERC20Facade";
import {OPT_ERC20Facade} from "./facades/OPT_Network/OPT_ERC20Facade";
import {POL_ERC20Facade} from "./facades/POL_Network/POL_ERC20Facade";
import {AVAX_ERC20Facade} from "./facades/AVAX/AVAX_ERC20Facade";

function getFacadeByNetwork(network?: NetworkType): IWeb3Facade | IWeb3TokenFacade {
  switch (network) {
    case 'eth': {
      return new ERC20Facade();
    }
    case 'bsc': {
      return new BEP20Facade()
    }
    case 'base': {
      return new BASE_ERC20Facade()
    }
    case 'arb': {
      return new ARB_ERC20Facade()
    }
    case 'opt': {
      return new OPT_ERC20Facade()
    }
    case 'pol': {
      return new POL_ERC20Facade()
    }
    case 'avax': {
      return new AVAX_ERC20Facade()
    }
    case 'doge': {
      return new DOGEFacade()
    }
    case 'sol': {
      return new SPLFacade()
    }
    case 'xrp': {
      return new XRPFacade()
    }
    default:
      throw new Error(`Network ${network} is not implemented for Consolidation tool.`);
  }
}

const FactoryConsolidationTool = (props: any) => {
  const {network} = useParams<{ network: NetworkType }>()

  const facade: IWeb3Facade | IWeb3TokenFacade = getFacadeByNetwork(network)
  const ReadyComponent = withFacade(ConsolidationToolView, facade)

  return (<ReadyComponent/>);
};

export {FactoryConsolidationTool}