import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import arbLogo from '../../assets/images/arb-icon.svg'

const ARB_DEFAULT_IMG = arbLogo as string

const ARBTokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://basescan.org/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://www.base.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  ARB: {
    "title": "Token ARB",
    "img": arbLogo,
    "rate": "$0.91",
    "address": "0x912CE59144191C1204E64559FE8253a0e49E6548",
    "link": "https://arbiscan.io/token/0x912CE59144191C1204E64559FE8253a0e49E6548",
    "group": `Tokens (BEP 20)`,
    "website": "https://arbitrum.io/",
    "desc": "",
    "decimal": 18,
    "symbol": "ARB"
  },
  USDT: {
    "title": "Token USDT",
    "img": "https://arbiscan.io/token/images/tether_32.png",
    "rate": "$1.00",
    "address": "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    "link": "https://arbiscan.io/token/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    "group": `Tokens (BEP 20)`,
    "website": "https://tether.to/",
    "desc": "",
    "decimal": 6,
    "symbol": "USDT"
  },
}

export {ARBTokens, ARB_DEFAULT_IMG}