import {IWeb3Facade} from "../IWeb3Facade";
import {HexStr} from "../../../../store/web3/web3";
import {
  IGeneralTxData,
  ITransactionPriorityEnum,
} from "../../types";
import {BEP20Tokens} from "../../../../store/bscscan/BEP20Tokens";
import {ETHFacade, InitDataType} from "../ETH_Network/ETHFacade";
import {BEP20TestnetTokens} from "../../../../store/bscscan/BEP20TestnetTokens";
import {GasHelper} from "../../../../helpers";

export interface ITxEthData extends IGeneralTxData {
  from: HexStr,
  to: HexStr,
  value: HexStr,
  chainId: HexStr,
  networkId: HexStr,
  gas: HexStr,
  gasPrice: number | string,
  nonce: HexStr,
}

const TransactionPriorityEnum: ITransactionPriorityEnum = {
  low: "low",
  medium: "medium",
  high: "high"
} as const

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IBinanceGasPrice {
  slow: bigint;
  average: bigint,
  fast: bigint
}

export const BscInitData:InitDataType = {
  defaultTransactionPriority: TransactionPriorityEnum.medium,
  transactionPriorityOptions: {
    [TransactionPriorityEnum.low]: "Standard",
    [TransactionPriorityEnum.medium]: "Fast",
    [TransactionPriorityEnum.high]: "Rapid"
  },
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_LINK_FOR_BSC_GAS_PRICE_API_KEY,
    url: process.env.REACT_APP_LINK_FOR_BSC_GAS_PRICE_API
  },
  web3HttpProviderLink: process.env.REACT_APP_BSC_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? BEP20TestnetTokens : BEP20Tokens,
  network: 'bsc',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BSC_SCAN
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IBinanceGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class BSCFacade extends ETHFacade implements IWeb3Facade {

  constructor() {
    super(BscInitData)
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IBinanceGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const {getGasPriceInWei} = this._web3Provider
      const slowInWei: bigint = await getGasPriceInWei()
      switch (transactionPriority) {
        case 'slow':
          return GasHelper.gasPricePlusPercent(slowInWei, 10)
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
      }
    }

    return gasPriceResult[transactionPriority as keyof IBinanceGasPrice] || BigInt(0)
  }
}

export {BSCFacade}