import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import avalancheLogo from '../../assets/images/avalanche-icon.svg';

const AVAX_DEFAULT_IMG = avalancheLogo as string

const AVAXTokens: ITokenDict = {
  [NetworkCurrencyEnum.avax]: {
    "title": "AVAX",
    "img": "https://snowscan.xyz/assets/avax/images/svg/logos/token-light.svg",
    "rate": "$36.38",
    "address": undefined,
    "link": "https://snowscan.xyz/",
    "group": `Base Currency (${NetworkCurrencyEnum.avax})`,
    "website": "https://www.avax.network/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.avax
  },
  WAVAX: {
    "title": "Wrapped AVAX",
    "img": avalancheLogo,
    "rate": "$2.07",
    "address": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    "link": "https://snowscan.xyz/token/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
    "group": `Tokens (BEP 20)`,
    "website": "https://www.avax.network/",
    "desc": "",
    "decimal": 18,
    "symbol": "WAVAX"
  },
  USDt: {
    "title": "Token USDT",
    "img": "https://snowscan.xyz/token/images/tether_32.png",
    "rate": "$1.00",
    "address": "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    "link": "https://snowscan.xyz/token/0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7",
    "group": `Tokens (BEP 20)`,
    "website": "https://tether.to/",
    "desc": "",
    "decimal": 6,
    "symbol": "USDt"
  },
}

export {AVAXTokens, AVAX_DEFAULT_IMG}