import {ITransactionPriorityEnum} from "../../ConsolidationTool/types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";
import {GasHelper} from "../../../helpers";
import {AddressHexStr, IContractAbiFragment, ITxDataSimple} from "../../../models/chainScan.models";
import {createPublicClient, http} from "viem";
import {toHex} from "../../../store/web3/web3";
import {
  IDataEstimateDisperseTransactions,
  IEstimateFeeForDisperseResultType
} from "../types";
import {polygon, polygonAmoy} from "viem/chains";
import {
  POLDisperseABI,
  POLDisperseContractAddress,
  POLTestnetDisperseContractAddress
} from "../../../store/polscan/POLDisperseABI";
import {publicActionsL2} from "viem/op-stack";

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IPOLGasPrice {
  slow: bigint,
  average: bigint,
  fast: bigint
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IPOLGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class POL_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected _disperseContractABI: IContractAbiFragment[]
  protected _disperseContractAddress: AddressHexStr

  protected static _feeL1ForSingleTransaction: bigint = BigInt(0)
  protected static _feeL1ForMultipleTransaction: bigint = BigInt(0)

  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_POL_WEB3_HTTP_PROVIDER,
      network: "pol",
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_POL_SCAN,
      defaultTransactionPriority: "fast",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_LINK_FOR_POL_GAS_PRICE_API_KEY,
        url: process.env.REACT_APP_LINK_FOR_POL_GAS_PRICE_API,
        devUrl: process.env.REACT_APP_LINK_FOR_POL_SCAN_API
      },
      addressesChunkSize: 100
    })

    this._disperseContractAddress = process.env.REACT_APP_ENVIRONMENT === 'dev' ?
      POLTestnetDisperseContractAddress : POLDisperseContractAddress
    this._disperseContractABI = POLDisperseABI
  }

  async estimateTransactions(data: IDataEstimateDisperseTransactions): Promise<IEstimateFeeForDisperseResultType> {
    const estimatedFee = await super.estimateTransactions(data)

    estimatedFee.notOptimizedFeeInUnit += GasHelper.gasPricePlusPercent(estimatedFee.notOptimizedFeeInUnit, 10)
    estimatedFee.optimizedFeeInUnit += GasHelper.gasPricePlusPercent(estimatedFee.optimizedFeeInUnit, 10)

    return estimatedFee
  }

  resetInfoForSendTransaction() {
    super.resetInfoForSendTransaction();
    POL_DisperseFacade._feeL1ForSingleTransaction = BigInt(0)
    POL_DisperseFacade._feeL1ForMultipleTransaction = BigInt(0)
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IPOLGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const {getGasPriceInWei} = this._web3Provider
      const slowInWei: bigint = await getGasPriceInWei()
      switch (transactionPriority) {
        case 'slow':
          return GasHelper.gasPricePlusPercent(slowInWei, 10)
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
      }
    }

    return gasPriceResult[transactionPriority as keyof IPOLGasPrice] || BigInt(0)
  }
}

export {POL_DisperseFacade}