import {IContractAbiFragment} from "../../models/chainScan.models";

/**
 * more info by next links
 * https://optimistic.etherscan.io/address/0xd152f549545093347a162dce210e7293f1452150#writeContract
 * https://disperse.app/
 * https://disperse.app/disperse.pdf
 */
const OPTDisperseContractAddress = "0xD152f549545093347A162Dce210e7293f1452150" as const

const OPTTestnetDisperseContractAddress = "0xF7164EF7d76Eb99C6BCC1059501582bA670dee82" as const
const MAX_TX_IN_ETH = 800
const MAX_TX_IN_TOKEN = 180
const OPTDisperseABI: IContractAbiFragment[] = [{
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseTokenSimple",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseToken",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "recipients", "type": "address[]"}, {"name": "values", "type": "uint256[]"}],
  "name": "disperseEther",
  "outputs": [],
  "payable": true,
  "stateMutability": "payable",
  "type": "function"
}]

export {
  OPTDisperseABI,
  OPTDisperseContractAddress,
  OPTTestnetDisperseContractAddress,
  MAX_TX_IN_ETH,
  MAX_TX_IN_TOKEN
}