import {
  AddressType,
  EstimateResultType,
  IMapValueByAddress,
  ITokenInfo,
  ITransactionPriorityEnum
} from "../../types";
import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import {
  ERC20Facade,
  IDataForGenerateERC20Transactions,
  IDataForSendERC20Transactions,
  ITxErc20Data
} from "../ETH_Network/ERC20Facade";
import ERC20TokenABI from "../../../../store/etherscan/ERC20TokenABI";
import {ApiScanResponse} from "../../../../models/chainScan.models";
import {GasHelper} from "../../../../helpers";
/**
 * Solution for L2 chain
 */
import {AvalancheInitData, AVAXFacade} from "./AVAXFacade";
import {AVAX_DEFAULT_IMG} from "../../../../store/avaxscan/AVAXTokens";

class AVAX_ERC20Facade extends ERC20Facade implements IWeb3TokenFacade {
  protected static _feeL1: bigint = BigInt(0)

  constructor() {
    super({
      baseInitData: AvalancheInitData,
      tokenInitData: {
        defaultTokenImage: AVAX_DEFAULT_IMG,
        abi: ERC20TokenABI,
        fetchTokenConf: {
          apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_AVAX_SCAN_API,
          url: process.env.REACT_APP_LINK_FOR_AVAX_SCAN_API
        }
      },
      addressesChunkSize: 100
    });
  }

  getTimeout(): number {
    return 1000;
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const parentFacade = new AVAXFacade(AvalancheInitData)
    return parentFacade._fetchGasPriceInWei(transactionPriority)
  }

  async generateTransactions(data: IDataForGenerateERC20Transactions, tokenAddress?: AddressType): Promise<EstimateResultType> {
    if (!tokenAddress) {
      const parentFacade = new AVAXFacade(AvalancheInitData)
      return await parentFacade.generateTransactions(data)
    }
    return await super.generateTransactions(data, tokenAddress)
  }

  async sendTransactions(data: IDataForSendERC20Transactions, tokenAddress?: AddressType): Promise<IMapValueByAddress> {
    if (!tokenAddress) {
      const parentFacade = new AVAXFacade(AvalancheInitData)
      return await parentFacade.sendTransactions(data)
    }

    return super.sendTransactions(data)
  }

  async fetchTokenInfo(tokenAddress: string): Promise<ITokenInfo> {
    const params = {
      module: 'account',
      action: 'tokentx',
      contractaddress: tokenAddress,
      page: '1',
      offset: '1',
      apikey: this._fetchTokenConf.apikey
    }
    const data: ApiScanResponse<{
      contractAddress: string;
      tokenDecimal: string;
      tokenName: string;
      tokenSymbol: string;
    }[]> = await fetch(
      this._fetchTokenConf.url + '/?' + new URLSearchParams(params),
      {cache: "force-cache"}
    ).then(response => response.json())

    if (parseInt(data?.status || '0') === 0 && !data.result) {
      throw new Error(data.message)
    }

    if (parseInt(data?.status || '0') === 1 && Object.values(data.result).length) {
      const dataToken = data.result[0]

      return {
        symbol: dataToken.tokenSymbol,
        title: dataToken.tokenName,
        address: dataToken.contractAddress,
        decimal: Number(dataToken.tokenDecimal),
        img: this.tokensDict[dataToken?.tokenSymbol]?.img || this.defaultTokenImage
      };
    }
    throw new Error("Something was wrong...");
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxErc20Data>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)

    txDataForEstimateByAddress.forEach((_, address) => {
      const currentFee = feeDataByAddress.get(address)!
      feeDataByAddress.set(address, GasHelper.gasPricePlusPercent(currentFee, 10))
    })

    return {txDataByAddress, feeDataByAddress}
  }
}

export {AVAX_ERC20Facade}