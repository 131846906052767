import {BSCFacade, BscInitData, ITxEthData} from "./BSCFacade";
import {HexStr} from "../../../../store/web3/web3";
import BEP20TokenABI from "../../../../store/bscscan/BEP20TokenABI";
import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import {ERC20Facade} from "../ETH_Network/ERC20Facade";
import {BEP20_DEFAULT_IMG} from "../../../../store/bscscan/BEP20Tokens";
import {ITokenInfo, ITransactionPriorityEnum} from "../../types";
import {ApiScanResponse, ITransferEvents} from "../../../../models/chainScan.models";

export interface ITxErc20Data extends ITxEthData {
  data: HexStr
}

class BEP20Facade extends ERC20Facade implements IWeb3TokenFacade {
  constructor() {
    super({
      baseInitData: BscInitData,
      tokenInitData: {
        defaultTokenImage: BEP20_DEFAULT_IMG,
        abi: BEP20TokenABI,
        fetchTokenConf: {
          apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_BSC_SCAN_API,
          url: process.env.REACT_APP_LINK_FOR_BSC_SCAN_API
        }
      }
    });
  }

  async fetchTokenInfo(tokenAddress: string): Promise<ITokenInfo> {
    const params = {
      module: 'account',
      action: 'tokentx',
      contractaddress: tokenAddress,
      page: '1',
      offset: '1',
      apikey: this._fetchTokenConf.apikey
    }
    const data: ApiScanResponse<ITransferEvents[]> = await fetch(
      this._fetchTokenConf.url + '/?' + new URLSearchParams(params),
      {cache: "force-cache"}
    ).then(response => response.json())

    if (parseInt(data?.status || '0') === 0 && !data.result) {
      throw new Error(data.message)
    }

    if (parseInt(data?.status || '0') === 1 && data.result.length) {
      const dataToken = data.result[0]

      return {
        symbol: dataToken.tokenSymbol,
        title: dataToken.tokenName,
        address: tokenAddress,
        decimal: Number(dataToken.tokenDecimal),
        img: this.tokensDict[dataToken?.tokenSymbol]?.img || this.defaultTokenImage
      };
    }
    throw new Error("Something was wrong...");
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const parentFacade = new BSCFacade()
    return parentFacade._fetchGasPriceInWei(transactionPriority)
  }
}

export {BEP20Facade};