import {IContractAbiFragment} from "../../models/chainScan.models";

/**
 * more info by next links
 * https://snowtrace.io/address/0xC813978A4c104250B1d2bC198cC7bE74b68Cd81b/contract/43114/code
 */
const AVAXDisperseContractAddress = "0xC813978A4c104250B1d2bC198cC7bE74b68Cd81b" as const

const AVAXTestnetDisperseContractAddress = "0xF7164EF7d76Eb99C6BCC1059501582bA670dee82" as const
const MAX_TX_IN_ETH = 800
const MAX_TX_IN_TOKEN = 180
const AVAXDisperseABI: IContractAbiFragment[] = [{
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseTokenSimple",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseToken",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "recipients", "type": "address[]"}, {"name": "values", "type": "uint256[]"}],
  "name": "disperseEther",
  "outputs": [],
  "payable": true,
  "stateMutability": "payable",
  "type": "function"
}]

export {
  AVAXDisperseABI,
  AVAXDisperseContractAddress,
  AVAXTestnetDisperseContractAddress,
  MAX_TX_IN_ETH,
  MAX_TX_IN_TOKEN
}