import {IWeb3Facade} from "../IWeb3Facade";
import {
  fromWei,
  GAS_FOR_SEND_ETHEREUM,
  HexStr,
  isAddress,
  isHexStrict,
  setProviderWeb3,
  toBigInt,
  toHex,
  toWei,
  Web3InitiatedType
} from "../../../../store/web3/web3";
import {
  AddressHexStr,
  ApiScanResponse,
  IGasPrice,
  IGasPriceResult,
  IRpcResponse,
  ITxBeforeEstimateGas,
  ITxTokenBeforeEstimateGas
} from "../../../../models/chainScan.models";
import {ERC20Tokens} from "../../../../store/etherscan/ERC20Tokens";
import {
  AddressType,
  BalanceDataByAddress,
  EstimateResultType,
  IAccount,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkType,
  PrivateKeyType
} from "../../types";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import {Transaction} from "web3-types";
import {ITxErc20Data} from "./ERC20Facade";
import {ERC20TestnetTokens} from "../../../../store/etherscan/ERC20TestnetTokens";
import {GasHelper} from "../../../../helpers";


interface IDataForGenerateETHTransactions extends IDataForGenerateTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

interface IDataForSendETHTransactions extends IDataForSendTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxEthData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

export interface ITxEthData extends IGeneralTxData {
  from: HexStr,
  to: HexStr,
  value: bigint,
  chainId: bigint,
  networkId: bigint,
  gas: bigint,
  gasPrice: bigint,
  nonce: bigint,
}

export type InitDataType = {
  defaultTransactionPriority: keyof ITransactionPriorityEnum,
  transactionPriorityOptions: ITransactionPriorityEnum,
  tokensDict: ITokenDict,
  network: NetworkType,
  linkForTxScan: string,
  web3HttpProviderLink: string,
  fetchGasPriceConf: { apikey: string; url: string, devUrl?: string|null },
  environment?: string,
  limitPrivateKeys?: number,
  addressesChunkSize?: number
}

const TransactionPriorityEnum: ITransactionPriorityEnum = {
  low: "low",
  medium: "medium",
  high: "high"
} as const

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IETHGasPrice {
  low: bigint,
  medium: bigint,
  high: bigint
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IETHGasPrice {
  return {
    low: BigInt(rawResult[1].gasPrice),
    medium: BigInt(rawResult[2].gasPrice),
    high: BigInt(rawResult[3].gasPrice)
  }
}

class ETHFacade implements IWeb3Facade {
  protected static gasPrice: bigint = BigInt(0);
  protected static nonceByAddress: IMapValueByAddress<number> = new Map()

  protected readonly _web3Provider: Web3InitiatedType;
  protected readonly _linkForTxScan: string;
  protected readonly _defaultTransactionPriority: keyof ITransactionPriorityEnum;
  protected readonly _transactionPriorityOptions: ITransactionPriorityEnum;
  protected readonly _tokensDict: ITokenDict;
  protected readonly _network: NetworkType;
  protected readonly _fetchGasPriceConf: { apikey: string; url: string, devUrl?: string|null };
  protected readonly _environment: string;
  protected readonly limitPrivateKeys: number;
  protected readonly addressesChunkSize: number;

  constructor(initData?: InitDataType) {
    this._defaultTransactionPriority = initData?.defaultTransactionPriority || TransactionPriorityEnum.medium
    this._transactionPriorityOptions = initData?.transactionPriorityOptions || {
      [TransactionPriorityEnum.low]: "Low",
      [TransactionPriorityEnum.medium]: "Average",
      [TransactionPriorityEnum.high]: "High"
    }
    this._tokensDict = initData?.tokensDict || (process.env.REACT_APP_ENVIRONMENT === 'dev' ? ERC20TestnetTokens : ERC20Tokens)
    this._network = initData?.network || 'eth'
    this._linkForTxScan = initData?.linkForTxScan || process.env.REACT_APP_LINK_FOR_TX_ETH_SCAN

    this._web3Provider = setProviderWeb3(initData?.web3HttpProviderLink || process.env.REACT_APP_ETH_WEB3_HTTP_PROVIDER)
    this._fetchGasPriceConf = initData?.fetchGasPriceConf || {
      apikey: process.env.REACT_APP_LINK_FOR_ETH_GAS_PRICE_API_KEY,
      url: process.env.REACT_APP_LINK_FOR_ETH_GAS_PRICE_API,
      devUrl: process.env.REACT_APP_LINK_FOR_ETH_SCAN_API
    }
    this._environment = initData?.environment || process.env.REACT_APP_ENVIRONMENT

    this.limitPrivateKeys = initData?.limitPrivateKeys || 10000;
    this.addressesChunkSize = initData?.addressesChunkSize || 800;
  }

  get tokensDict() {
    return this._tokensDict
  }

  get network() {
    return this._network
  }

  get linkForTxScan() {
    return this._linkForTxScan
  }

  get defaultTransactionPriority() {
    return this._defaultTransactionPriority
  }

  get transactionPriorityOptions() {
    return this._transactionPriorityOptions
  }

  getLimitPrivateKeys() {
    return this.limitPrivateKeys
  }

  getAddressesChunkSize() {
    return this.addressesChunkSize
  }

  getTimeout(): number {
    return 200;
  }

  async fetchBaseCurrencyBalanceDataByAddress(addressList: Set<AddressType>): Promise<BalanceDataByAddress> {
    if (addressList.size > this.getAddressesChunkSize()) {
      throw new Error(`Address size should be lower than ${this.getAddressesChunkSize()}`);
    }

    const {
      BatchRequest,
      getBalance
    } = this._web3Provider
    const balanceByAddress: BalanceDataByAddress = new Map();

    const batchInfo = new BatchRequest();
    addressList.forEach(address => {
      batchInfo.add(getBalance.request(address as AddressHexStr));
    })

    const dataInfo = await batchInfo.execute({timeout: 30000})
    for (let item of dataInfo) {
      if (item.error) {
        const errorData = item.error as JsonRpcError
        throw new Error(errorData.message + `[${errorData.code}]`)
      }
      let itemSuccess = item as IRpcResponse

      balanceByAddress.set(itemSuccess.id, getBalance.outputFormatter(itemSuccess.result))
    }
    return balanceByAddress
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    if (ETHFacade.gasPrice === BigInt(0)) {
      const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      });
      const result = await response.json() as {
        timestamp: string,
        lastBlock: number,
        avgTime: number,
        avgTx: number,
        avgGas: number,
        avgL1Fee: number,
        speeds: IRawGasPriceItem[]
      };
      /**
       * In test(dev) env use blockscout rpc to get actual price for testnet
       */
      let gasPriceResult: IETHGasPrice = adapterGasPrice(result.speeds)
      ETHFacade.gasPrice = gasPriceResult[transactionPriority as keyof IETHGasPrice] || BigInt(0)

      if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
        const response = await fetch(`${this._fetchGasPriceConf.devUrl}/eth-rpc`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            "id": 0,
            "method": "eth_gasPrice",
            "jsonrpc": "2.0",
            "params": []
          })
        })
        const result = await response.json() as {
          "jsonrpc": string,
          "result": HexStr,
          "id": number
        }
        const slowInWei: bigint = BigInt(result.result)

        switch (transactionPriority) {
          case 'low':
            ETHFacade.gasPrice = GasHelper.gasPricePlusPercent(slowInWei, 10)
            break
          case 'medium':
            ETHFacade.gasPrice = GasHelper.gasPricePlusPercent(slowInWei, 50)
            break
          case 'high':
            ETHFacade.gasPrice = GasHelper.gasPricePlusPercent(slowInWei, 100)
            break
        }
      }
    }

    return ETHFacade.gasPrice;
  }

  resetGasPriceAndNonce() {
    ETHFacade.gasPrice = BigInt(0)
    ETHFacade.nonceByAddress = new Map()
  }

  async __fetchNonce(addressList: Set<AddressType>) {
    const {
      BatchRequest,
      getTransactionCount,
    } = this._web3Provider

    const batchNonce = new BatchRequest();

    addressList.forEach(address => {
      batchNonce.add(getTransactionCount.request(address as AddressHexStr));
    })
    const dataNonce = await batchNonce.execute({timeout: 30000})
    for (let item of dataNonce) {
      if (item.error) {
        const errorData = item.error as JsonRpcError
        throw new Error(errorData.message + `[${errorData.code}]`)
      }
      let itemSuccess = item as IRpcResponse
      if (!ETHFacade.nonceByAddress.has(itemSuccess.id)) {
        ETHFacade.nonceByAddress.set(itemSuccess.id, getTransactionCount.outputFormatter(itemSuccess.result))
      }
    }
    return ETHFacade.nonceByAddress
  }

  async __getGasLimit(sender: AddressType, receiver: AddressType): Promise<bigint> {
    return BigInt(GAS_FOR_SEND_ETHEREUM)
  }

  async generateTransactions(
    data: IDataForGenerateETHTransactions
  ): Promise<EstimateResultType> {
    const {baseCurrencyBalanceData, transactionPriority, receiverAddress} = data
    const txDataByAddress: IMapValueByAddress<ITxEthData> = new Map()

    const gasPriceInWei = await this._fetchGasPriceInWei(transactionPriority)
    const nonceByAddress = await this.__fetchNonce(new Set(baseCurrencyBalanceData.keys()))
    const chainId = await this._web3Provider.getChainId()
    const networkId = await this._web3Provider.getNetworkId()
    const gas = await this.__getGasLimit(receiverAddress, baseCurrencyBalanceData.keys().next().value)

    baseCurrencyBalanceData.forEach((balance, address) => {
      const accountHex: AddressHexStr = address as AddressHexStr
      if (accountHex.toLowerCase() === receiverAddress?.toLowerCase()) {
        return
      }

      if (balance > 0) {
        const feeWei = gas * gasPriceInWei
        const amountToSend = balance - feeWei

        txDataByAddress.set(accountHex, {
          from: accountHex,
          to: receiverAddress,
          chainId: chainId,
          networkId: BigInt(networkId),
          gasPrice: gasPriceInWei,
          gas: gas,
          value: amountToSend,
          nonce: BigInt(nonceByAddress.get(address)!),
        })
      }
    })

    return await this._estimateFee(txDataByAddress, gasPriceInWei)
  }

  async sendTransactions(data: IDataForSendETHTransactions): Promise<IMapValueByAddress> {
    const {
      privateKeyByAddress, baseCurrencyBalanceData,
      receiverAddress, transactionPriority, transactionDataByAddress
    } = data

    const resultTxReceipt: IMapValueByAddress<HexStr> = new Map()
    const signedTxByAddress = new this._web3Provider.BatchRequest()
    const gasPriceInWei = await this._fetchGasPriceInWei(transactionPriority)

    const {feeDataByAddress, txDataByAddress} = await this._estimateFee(transactionDataByAddress, gasPriceInWei)

    for (const address of txDataByAddress.keys()) {
      if (address.toLowerCase() === receiverAddress.toLowerCase() || !txDataByAddress.has(address)) {
        continue
      }

      const balance = baseCurrencyBalanceData.get(address) ?? BigInt(0)
      const txDataBeforeToHex = txDataByAddress.get(address)
      const feeWei = feeDataByAddress.get(address) ?? BigInt(0)

      if (privateKeyByAddress.has(address) && txDataBeforeToHex && (balance > feeWei)) {
        txDataBeforeToHex.gasPrice = gasPriceInWei

        //TODO temporary solution //Possible error "insufficient funds for gas * price + value: balance ..."
        if (txDataBeforeToHex.value !== BigInt(0)) {
          console.log('many')
          //update amount to send because of new gas price
          txDataBeforeToHex.value = balance - feeWei
        }
        console.log('-----start-----')
        console.table({
          gasPriceInWei,
          feeWei,
          balance,
        })
        console.table({...txDataBeforeToHex})
        console.log('-----end-----')

        const txDataHex: Transaction = this._toHexTxData(txDataBeforeToHex)

        const {rawTransaction} = await this._web3Provider.signTransaction(txDataHex, privateKeyByAddress.get(address)!)
        signedTxByAddress.add(this._web3Provider.sendSignedTransaction.request(rawTransaction, address as AddressHexStr))
      }
    }
    const dataBatchTx = await signedTxByAddress.execute({timeout: 60000})
    for (let txResult of dataBatchTx) {
      if (txResult.error) {
        const errorData = txResult.error as JsonRpcError
        throw new Error(`${errorData.message} [${errorData.code}]`)
      }
      let itemSuccess = txResult as IRpcResponse
      resultTxReceipt.set(itemSuccess.id, itemSuccess.result as HexStr)
    }
    return resultTxReceipt
  }

  validateAddress(address: AddressType): boolean {
    return isHexStrict(address) && isAddress(address)
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    if (!isHexStrict(privateKey)) {
      privateKey = `0x${privateKey}`
    }

    return this._web3Provider.privateKeyToAccount(privateKey)
  }

  toUnitFromBaseCurrency(amount: string): bigint {
    return BigInt(toWei(amount, 'ether'))
  }

  toBaseCurrencyFromUnit(amount: bigint): string {
    const amountBase: string = fromWei(amount, 'ether')
    if (amountBase[amountBase.length - 1] === '.') {
      return amountBase.substring(0, amountBase.length - 1)
    }
    return amountBase
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()
    if (txDataForEstimateByAddress.size === 0) {
      return {txDataByAddress: txDataForEstimateByAddress, feeDataByAddress}
    }

    const tx = txDataForEstimateByAddress.values().next().value
    const gas = await this.__getGasLimit(tx.from, tx.to)
    const feeWei = gas * gasPriceInWei
    console.log('eth (L2)', feeWei)
    for (const address of txDataForEstimateByAddress.keys()) {
      feeDataByAddress.set(address, feeWei)
    }

    return {txDataByAddress: txDataForEstimateByAddress, feeDataByAddress}
  }

  protected _toHexTxData(txData: ITxEthData | ITxErc20Data): ITxBeforeEstimateGas | ITxTokenBeforeEstimateGas {
    return {
      ...txData,
      gasPrice: toHex(txData.gasPrice),
      chainId: toHex(txData.chainId),
      networkId: toHex(txData.networkId),
      gas: toHex(txData.gas),
      value: toHex(txData.value),
      nonce: toHex(txData.nonce),
    }
  }
}

export {ETHFacade}