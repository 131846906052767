import {IWeb3Facade} from "../IWeb3Facade";
import {
  IMapValueByAddress,
  ITransactionPriorityEnum
} from "../../types";
import {ETHFacade, InitDataType, ITxEthData} from "../ETH_Network/ETHFacade";
import {GasHelper} from "../../../../helpers";
import {AVAXTestnetTokens} from "../../../../store/avaxscan/AVAXTestnetTokens";
import {AVAXTokens} from "../../../../store/avaxscan/AVAXTokens";

export const AvalancheTransactionPriorityEnum: ITransactionPriorityEnum = {
  slow: "slow",
  average: "average",
  fast: "fast"
} as const

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IAvalancheGasPrice {
  slow: bigint;
  average: bigint,
  fast: bigint
}

export const AvalancheInitData: InitDataType = {
  defaultTransactionPriority: AvalancheTransactionPriorityEnum.average,
  transactionPriorityOptions: {
    [AvalancheTransactionPriorityEnum.slow]: "Slow",
    [AvalancheTransactionPriorityEnum.average]: "Average",
    [AvalancheTransactionPriorityEnum.fast]: "Fast",
  },
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_LINK_FOR_AVAX_GAS_PRICE_API_KEY,
    url: process.env.REACT_APP_LINK_FOR_AVAX_GAS_PRICE_API,
    devUrl: process.env.REACT_APP_LINK_FOR_AVAX_SCAN_API
  },
  web3HttpProviderLink: process.env.REACT_APP_AVAX_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? AVAXTestnetTokens : AVAXTokens,
  network: 'avax',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_AVAX_SCAN,
  addressesChunkSize: 100
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IAvalancheGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class AVAXFacade extends ETHFacade implements IWeb3Facade {
  protected static _feeL1: bigint = BigInt(0)

  constructor(initData?: InitDataType) {
    super(initData || AvalancheInitData)
  }

  getTimeout(): number {
    return 1000;
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IAvalancheGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const {getGasPriceInWei} = this._web3Provider
      const slowInWei: bigint = await getGasPriceInWei()
      switch (transactionPriority) {
        case 'slow':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 200)
      }
    }

    return gasPriceResult[transactionPriority as keyof IAvalancheGasPrice] || BigInt(0)
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)

    txDataForEstimateByAddress.forEach((_, address) => {
      const currentFee = feeDataByAddress.get(address)!
      feeDataByAddress.set(address, GasHelper.gasPricePlusPercent(currentFee, 10))
    })

    return {txDataByAddress, feeDataByAddress}
  }
}

export {AVAXFacade}